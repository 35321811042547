<ng-container [ngSwitch]="iconName">
  <ng-container *ngSwitchCase="'home'">
    <svg ibmIconHome [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'application'">
    <svg ibmIconApplication [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'help'">
    <svg ibmIconHelp [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'add'">
    <svg ibmIconAdd [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'aperture'">
    <svg ibmIconAperture [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'search'">
    <svg ibmIconSearch [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'notification'">
    <svg ibmIconNotification [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'chat'">
    <svg ibmIconChat [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'user'">
    <svg ibmIconUser [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'tools'">
    <svg ibmIconTools [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'arrowUp'">
    <svg ibmIconArrowUp [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'arrowDown'">
    <svg ibmIconArrowDown [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'arrowLeft'">
    <svg ibmIconArrowLeft [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'arrowRight'">
    <svg ibmIconArrowRight [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'cloudDataOps'">
    <svg ibmIconCloudDataOps [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'virtualPrivateCloudAlt'">
    <svg ibmIconVirtualPrivateCloudAlt [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'checkMarkOutline'">
    <svg ibmIconCheckmarkOutline [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'checkmark'">
    <svg ibmIconCheckmark [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'checkmarkFilled'">
    <svg ibmIconCheckmarkFilled [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'caretRight'">
    <svg ibmIconCaretRight [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'caretLeft'">
    <svg ibmIconCaretLeft [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'renew'">
    <svg ibmIconRenew [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'alarm'">
    <svg ibmIconAlarm [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'minimize'">
    <svg ibmIconMinimize [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'cloudDownload'">
    <svg ibmIconCloudDownload [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'analytics'">
    <svg ibmIconAnalytics [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'activity'">
    <svg ibmIconActivity [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'dashboard'">
    <svg ibmIconDashboard [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'dashboardReference'">
    <svg ibmIconDashboardReference [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'task'">
    <svg ibmIconTask [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'catalog'">
    <svg ibmIconCatalog [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'document'">
    <svg ibmIconDocument [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'email'">
    <svg ibmIconEmail [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'send'">
    <svg ibmIconSend [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'sendAlt'">
    <svg ibmIconSendAlt [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'userAdmin'">
    <svg ibmIconUserAdmin [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'events'">
    <svg ibmIconEvents [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'userAvatar'">
    <svg ibmIconUserAvatar [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'folder'">
    <svg ibmIconFolder [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'folderShared'">
    <svg ibmIconFolderShared [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'recentlyViewed'">
    <svg ibmIconRecentlyViewed [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'image'">
    <svg ibmIconImage [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'imageSearch'">
    <svg ibmIconImageSearch [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'error'">
    <svg ibmIconError [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'phraseSentiment'">
    <svg ibmIconPhraseSentiment [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'tableSplit'">
    <svg ibmIconTableSplit [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'chartColumn'">
    <svg ibmIconChartColumn [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'magicWand'">
    <svg ibmIconMagicWand [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'textTracking'">
    <svg ibmIconTextTracking [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'textCreation'">
    <svg ibmIconTextCreation [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'trashCan'">
    <svg ibmIconTrashCan [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'menu'">
    <svg ibmIconMenu [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'radioButton'">
    <svg ibmIconRadioButton [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'radioButtonChecked'">
    <svg ibmIconRadioButtonChecked [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'settingsAdjust'">
    <svg ibmIconSettingsAdjust [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'settings'">
    <svg ibmIconSettings [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'edit'">
    <svg ibmIconEdit [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'checkboxChecked'">
    <svg ibmIconCheckboxChecked [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'cloudApp'">
    <svg ibmIconCloudApp [size]="iconSize"></svg>
  </ng-container>
  <ng-container *ngSwitchCase="'rotate'">
    <svg ibmIconRotate [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'api'">
    <svg ibmIconApi [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'bullhorn'">
    <svg ibmIconBullhorn [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'buildingInsights1'">
    <svg ibmIconBuildingInsights_1 [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'tag'">
    <svg ibmIconTag [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'time'">
    <svg ibmIconTime [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'chevronDown'">
    <svg ibmIconChevronDown [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'chevronUp'">
    <svg ibmIconChevronUp [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'chevronLeft'">
    <svg ibmIconChevronLeft [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'chevronRight'">
    <svg ibmIconChevronRight [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'draggable'">
    <svg ibmIconDraggable [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'archive'">
    <svg ibmIconArchive [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'maximize'">
    <svg ibmIconMaximize [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'star'">
    <svg ibmIconStar [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'download'">
    <svg ibmIconDownload [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'share'">
    <svg ibmIconShare [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'move'">
    <svg ibmIconMove [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'tableOfContents'">
    <svg ibmIconTableOfContents [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'upload'">
    <svg ibmIconUpload [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'package'">
    <svg ibmIconPackage [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'attachment'">
    <svg ibmIconAttachment [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'flag'">
    <svg ibmIconFlag [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'flagFilled'">
    <svg ibmIconFlagFilled [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'textBold'">
    <svg ibmIconTextBold [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'textItalic'">
    <svg ibmIconTextItalic [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'textUnderline'">
    <svg ibmIconTextUnderline [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'printer'">
    <svg ibmIconPrinter [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'overflowMenuHorizontal'">
    <svg ibmIconOverflowMenuHorizontal [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'overflowMenuVertical'">
    <svg ibmIconOverflowMenuVertical [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'openPanelFilledLeft'">
    <svg ibmIconOpenPanelFilledLeft [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'openPanelLeft'">
    <svg ibmIconOpenPanelLeft [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'openPanelRight'">
    <svg ibmIconOpenPanelRight [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'locked'">
    <svg ibmIconLocked [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'information'">
    <svg ibmIconInformation [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'phone'">
    <svg ibmIconPhone [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'subtract'">
    <svg ibmIconSubtract [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'money'">
    <svg ibmIconMoney [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'faceActivated'">
    <svg ibmIconFaceActivated [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'purchase'">
    <svg ibmIconPurchase [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'calendar'">
    <svg ibmIconCalendar [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'password'">
    <svg ibmIconPassword [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'location'">
    <svg ibmIconLocation [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'hourglass'">
    <svg ibmIconHourglass [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'inProgress'">
    <svg ibmIconInProgress [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'hearing'">
    <svg ibmIconHearing [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'favorite'">
    <svg ibmIconFavorite [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'pin'">
    <svg ibmIconPin [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'industry'">
    <svg ibmIconIndustry [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'portfolio'">
    <svg ibmIconPortfolio [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'exit'">
    <svg ibmIconExit [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'close'">
    <svg ibmIconClose [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'view'">
    <svg ibmIconView [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'apps'">
    <svg ibmIconApps [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'contourFinding'">
    <svg ibmIconWatsonHealthContourFinding [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'save'">
    <svg ibmIconSave [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'3dCursorAlt'">
    <svg ibmIconWatsonHealth_3DCursorAlt [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'logout'">
    <svg ibmIconLogout [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'rocket'">
    <svg ibmIconRocket [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'videoAdd'">
    <svg ibmIconVideoAdd [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'apple'">
    <svg ibmIconApple [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'bot'">
    <svg ibmIconBot [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'textLinkAnalysis'">
    <svg ibmIconTextLinkAnalysis [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'queryQueue'">
    <svg ibmIconQueryQueue [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'informationSquare'">
    <svg ibmIconInformationSquare [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'enterprise'">
    <svg ibmIconEnterprise [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'eventsalt'">
    <svg ibmIconEventsAlt [size]="iconSize"></svg>
  </ng-container>

  <ng-container *ngSwitchCase="'hashtag'">
    <!--<svg ibmIconHashtag [size]="iconSize"></svg>-->
    <svg xmlns="http://www.w3.org/2000/svg" [style.width]="iconSize" [style.height]="iconSize" viewBox="0 0 32 32">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
        </style>
      </defs>
      <path d="M28,12V10H22V4H20v6H12V4H10v6H4v2h6v8H4v2h6v6h2V22h8v6h2V22h6V20H22V12Zm-8,8H12V12h8Z" />
      <rect class="cls-1" [style.width]="iconSize" [style.height]="iconSize" />
    </svg>
  </ng-container>

  <ng-container *ngSwitchDefault>!!!</ng-container>
</ng-container>