import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'youpez-header-tools',
  templateUrl: './app-header-tools.component.html',
  styleUrls: ['./app-header-tools.component.scss']
})
export class AppHeaderToolsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
