<div class="app-a-box app-scrollbar-fix">
  <ng-scrollbar>
    <div class="app-header app-header--carbon">
      <div class="app-productive-heading-04">{{mainTitle}}</div>
    </div>
    <div class="app-content app-content--default z-0">
      <ng-content></ng-content>
    </div>
  </ng-scrollbar>
</div>

