import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "youpez-header-black-no-breadcrumbs",
  templateUrl: "./app-header-black-no-breadcrumbs.component.html",
  styleUrls: ["./app-header-black-no-breadcrumbs.component.scss"],
})
export class AppHeaderBlackNoBreadcrumbsComponent implements OnInit {
  @Input()
  bordered: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }
}
