<div class="app-breadcrumb">
  <ng-container *ngFor="let breadcrumb of breadcrumbs;let i=index">
    <ng-container *ngIf="breadcrumbs.length-1 !== i">
      <a class="app-breadcrumb__item" [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
        {{breadcrumb.label}}
      </a>
    </ng-container>
    <ng-container *ngIf="breadcrumbs.length-1 === i">
      <div class="app-breadcrumb__item app-breadcrumb__item--active">{{breadcrumb.label}}</div>
    </ng-container>
  </ng-container>
</div>
