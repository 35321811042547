<div class="absolute bottom-0 right-0 left-0 top-0 opacity-50 z-0 app-bg-pattern-svg"></div>
<div class="flex items-center h-full z-10 relative">
  <div class="px-16">
    <div class="app-display-04 mb-4">
      <div>Welcome to Youpez</div>
      <div>the professional admin</div>
      <div>platform built with </div>
      <div>competence</div>
    </div>
    <p class="app-body-long-02 opacity-75" style="max-width:440px;">Youpez helps you to focus on business
      logic with
      well-coded admin user interfaces and cutting edge best practices</p>
  </div>
</div>
<div class="absolute top-0 right-0 left-0 z-10">
  <div class="px-16 py-8 flex justify-between">
    <youpez-logo type="white" logoWidth="160px"></youpez-logo>
  </div>
</div>
<div class="absolute bottom-0 right-0 left-0 z-10">
  <div class="px-16 py-8 flex justify-between">
    <div>©2020 Youpez</div>
    <div>
      <a href="#" class="mr-3 text-white app-link">Privacy</a>
      <a href="#" class="mr-3 text-white app-link">Security</a>
      <a href="#" class="text-white app-link">Legal</a>
    </div>
  </div>
</div>

