export const environment = {
	production: false,
	backend_url: "https://staging.admin.api.portal.promon.no",
	insight_internal_api_url: "https://staging.api.hub.gc.app-analytic.com/int",
	keycloak_server_url: "https://staging.sso.promon.no",
	customer_client_url: "https://staging.portal.promon.no",
	keycloak_realm: "master",
	keycloak_clientid: "admin-portal-web-client",
	redirect_uri: "https://staging.admin.portal.promon.no/iam",
	client_url: "https://staging.admin.portal.promon.no",
	sentry_dsn:
		"https://664599f353bc46299ee2ef671a6b4c71@o4504752501620736.ingest.sentry.io/4505113549930496",
};
